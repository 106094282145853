import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import logo from './images/logo.png'


const ResponsiveAppBar = (props) => {
  const title = props.title
  const titlestyle = {
    fontFamily: 'Roboto Mono',
    fontWeight: 'Bold',
    flexGrow: { xs: 1, md: 0},
    letterSpacing: '.1rem',
    color: 'inherit',
    textDecoration: 'none'
};

  return (
    <AppBar 
      position='sticky' 
      elevation={0}
    >
      <Container 
        maxWidth='xl'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <Toolbar disableGutters>
          <Box
            component='img'
            sx={{
              height: 35,
              mr: 1.2
            }}
            src={logo}
          />
          <Typography
            className='header-title'
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={titlestyle}
          >          
            {title}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
