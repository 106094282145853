import AppBar from './AppBar.js';
import PostFeed from './Postfeed.js';
import PageNotFound from './NotFoundPage';
import Footer from './Footer.js'
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <ThemeProvider theme={theme}>
            <AppBar title='EngBlogs'></AppBar>

            <Routes>
              <Route exact path='/' element={<PostFeed />}/>
              <Route exact path='*' element={<PageNotFound />}/>
            </Routes>
            
            <Footer />
          </ThemeProvider>
        </div>
      </BrowserRouter>
    )  
  }
}

export default App;
