import { Container, Typography } from "@mui/material"

const PageNotFound = () => {
    return (
        <Container
            maxWidth="md"
            sx={{
                height: '50vh',
                marginTop: '30vh',
                textAlign: 'center',
            }}
            >
            <Typography variant="h1">
            404
            </Typography>
            <Typography variant="h4">
            Page Not Found
            </Typography>
        </Container>
    )
}
export default PageNotFound