import { Grid, Typography } from "@mui/material"

const Footer = () => {
    return (
        <Grid container
            sx={{
                minHeight: 30,
                marginTop: 5,
                marginBottom: 3
            }}
            alignItems='center'
        >
            <Grid item xs={12}
                sx={{
                    textAlign: 'center'
                }}
            >
            <Typography 
                variant='caption' 
                sx={{
                    fontFamily: 'Roboto Mono',
                }}>
            © {new Date().getFullYear()} hengyilin.xyz, All Rights Reserved.
            </Typography>
            </Grid>
        </Grid>
    )
}

export default Footer