import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let _theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#f5f5f5',
        },
        secondary: {
            main: '#5BCF80'
        }
    },
    typography: {
        fontFamily: "Roboto"
    },
    components: {
        MuiLink: {
            styleOverrides: {
              root: {
                color: '#3C3C3C',
                ":visited": {
                    color: '#A5A5A5'
                },
                ":hover": {
                    color: '#5BCF80'
                }
              },
            },
        }
    }
})

export const theme = responsiveFontSizes(
    _theme,
    {
        breakpoints: ['xs', 'md']
    }
)