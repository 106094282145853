import { Container, Grid, Typography, Link, Divider, Avatar } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Component } from 'react';

const he = require('he');
const dividerPost = {
    borderBottomWidth: '1px', 
    mt: 1,
};

class PostFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageToken: '',
            feed: new Map(),
            loading: false,
        };
    
        // This binding is necessary to make `this` work in the callback
        this.fetchPosts = this.fetchPosts.bind(this);
      }
    
    componentDidMount() {
        // strict mode will cause componentDidMount() called twice
        // see https://stackoverflow.com/questions/71755119/reactjs-componentdidmount-executes-twice
        if (this.mounted) {
            return
        }
        this.mounted = true
        this.fetchPosts()    
    }

    fetchPosts() {
        this.setState(
            { loading: true },
            () => fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/posts?limit=20&page_token=${this.state.pageToken}`)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState((state, props) => {
                        let feed = new Map(this.state.feed)
                        resp.posts.forEach(p => {
                            const date = new Date(p.published_time).toLocaleDateString()
                            if (!feed.has(date)) {
                            feed.set(date, [])
                            }
                            // feed.get(date).push(p)
                            feed.set(date, feed.get(date).concat([p]))
                        })
                        return {
                            pageToken: resp.page_token, 
                            feed: feed,
                            loading: false
                        }
                    })
                })
        )
    }
    
    render() {
        return (
            <Container 
                maxWidth="md"
                sx={{
                    marginTop: 2,
                    marginBottom: 2
                }}
            >
                <Grid container spacing={1}>
                    {this.renderDailyPosts()}
                </Grid>
                {
                    (this.state.pageToken !== '' && this.state.pageToken !== null) &&
                    <LoadingButton variant="text"
                        sx={{
                            float: 'right',
                            color: 'primary.dark',
                            ':hover': {
                                color: 'secondary.light'
                            }
                        }}
                        onClick={this.fetchPosts}
                        loading={this.state.loading}
                    >
                        <span>...More</span>
                    </LoadingButton>
                }  
            </Container>
        )
    }

    renderDailyPosts() {
        return Array.from(this.state.feed.entries()).map((g) => {
            const date = g[0]
            const posts = g[1]
            
            return (
                <Grid 
                    container 
                    key={date}
                    spacing={1} 
                    sx={{mt: 3 }}    
                >
                    <Grid item xs={12}>
                        <Typography
                            marginBottom={2}
                            variant="h6"
                            align="center"
                            sx={{
                                display: 'inline',
                                fontFamily: 'Roboto Mono',
                                fontWeight: 'Bold',
                                color: '#3C3C3C',
                                background: 'linear-gradient(0deg, rgba(91,207,128, 0) 0%, rgba(91,207,128, 0) 30%, rgba(91,207,128, 0.3) 30%, rgba(91,207,128, 0.2) 55%, rgba(91,207,128, 0) 55%, rgba(91,207,128, 0) 100%)'
                            }}
                        >
                        {date}
                        </Typography>
                    </Grid>
                    {posts.map((p, i) => this.renderPost(p, i))}
                </Grid>                
            )
        })
    }

    renderPost(post, i) {
        return (
            <Grid container item key={i} xs={12} sx={{mt: 1, mx: 2}}>
                <Avatar
                    src={`${process.env.PUBLIC_URL}/site-icons/${post.icon_path}`}
                    variant='square'
                    alt={post.site}
                    sx={{ width: 24, height: 24 }}>
                </Avatar>
                <Grid item xs={8} md={8}
                    sx={{                    
                        mt: 0.3,
                        ml: 1
                    }}
                >
                    <Typography
                        variant='body2'
                        sx={{
                            fontFamily: 'Roboto Mono',
                            color: '#3C3C3C'
                        }}
                    >
                    {post.site}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}
                    sx={{mt: 1}}
                >
                    <Link
                        href={post.url}
                        target="_blank"
                        variant='subtitle1'
                        color='#3C3C3C'
                        sx={{
                            fontWeight: 'Bold',
                            textDecoration: 'none', 
                        }}                        
                    >
                    {he.unescape(post.title)}
                    </Link>
                    <Divider sx={dividerPost}/>
                </Grid>                
            </Grid>
        )
    }

}

export default PostFeed
